var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-8"},[(_vm.showFormatSelections)?_c('FormBlock',{attrs:{"title":_vm.formatTitle,"description":_vm.formatDescription}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"pt-1 space-x-4",class:{ 'text-red-600': errors.length > 0 }},_vm._l((_vm.sampleTypes),function(sampleType){return _c('label',{key:sampleType,staticClass:"inline-flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.fileType),expression:"configuration.fileType"}],staticClass:"text-primary-600 form-radio disabled:text-neutral-400 disabled:cursor-not-allowed",attrs:{"type":"radio","name":"fileFormat","disabled":_vm.disabled || _vm.loading},domProps:{"value":sampleType,"checked":_vm._q(_vm.configuration.fileType,sampleType)},on:{"change":[function($event){return _vm.$set(_vm.configuration, "fileType", sampleType)},_vm.clearFiles]}}),_c('span',{staticClass:"ml-2 text-sm",class:sampleType === 'parquet' ? 'capitalize' : 'uppercase'},[_vm._v(" "+_vm._s(sampleType)+" ")])])}),0),(errors.length > 0)?_c('div',{staticClass:"mt-1 text-sm text-red-700"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1084696669)})],1):_vm._e(),(_vm.configuration.fileType && _vm.showSampleFileUpload)?_c('FormBlock',{attrs:{"title":_vm.sampleTitle,"description":_vm.sampleDescription}},[_c('ValidationProvider',{ref:"sampleUploadRef",attrs:{"rules":{ required: !_vm.sampleFile && _vm.emptySampleData && _vm.sampleRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center text-sm"},[(!_vm.disabled)?_c('label',{staticClass:"inline-flex items-center px-4 py-1 text-xs font-medium leading-5 tracking-wide text-white uppercase transition duration-150 ease-in-out border rounded border-neutral-600 hover:border-neutral-500 bg-neutral-600 hover:bg-neutral-500 focus:outline-none focus:ring-neutral focus:border-neutral-700 active:bg-neutral-700 disabled:bg-neutral-400",class:{
                        'opacity-50 pointer-events-none': _vm.loading,
                    },attrs:{"for":"sampleUpload"}},[_vm._v(" Browse ")]):_vm._e(),(!_vm.disabled)?_c('input',{ref:"sampleRef",staticClass:"hidden",attrs:{"id":"sampleUpload","type":"file","accept":_vm.acceptedFiles,"disabled":_vm.loading},on:{"click":function($event){$event.target.value = ''},"change":function($event){return _vm.sampleUploaded($event)}}}):_vm._e(),_c('div',{staticClass:"flex-grow",class:{
                        'text-red-600': errors.length > 0,
                        'text-neutral-600': errors.length === 0,
                        'ml-4': !_vm.disabled,
                    }},[_c('div',{staticClass:"flex items-center justify-between"},[(_vm.sampleFile)?_c('div',{staticClass:"flex items-center justify-between text-sm text-neutral-900"},[_c('DocumentIcon',{staticClass:"w-4 h-4 mx-2"}),_c('div',[_vm._v(_vm._s(_vm.sampleFile.name))]),_c('div',{staticClass:"ml-4 font-bold"},[_vm._v(_vm._s(_vm.formatBytes(_vm.sampleFile.size)))]),(_vm.configuration.isSampleCropped)?_c('div',{staticClass:"ml-4"},[_vm._v("(Sample has been cropped)")]):_vm._e()],1):(_vm.emptySampleData)?_c('div',{staticClass:"italic"},[_vm._v(" No file selected "+_vm._s(_vm.sampleMayBeCroppedMessage)+" ")]):_c('div',{staticClass:"italic"},[_vm._v("Sample "+_vm._s(_vm.sampleCroppedMessage)+" uploaded and saved")]),(errors.length > 0)?_c('div',{staticClass:"italic text-red-600"},[_c('ExclamationIcon',{staticClass:"w-5 h-5"})],1):_vm._e()])])])]}}],null,false,1276237764)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }