

















































import { ConfirmModal } from '@/app/components';
import { useAxios, useSockets } from '@/app/composable';
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloAPI } from '../../../api';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload, useSampleRun } from '../../../composable';
import { FileType, TaskStatus } from '../../../constants';
import { ApolloTask, FileHarvesterConfiguration, FilesData, TFile, TaskStep, WizardAction } from '../../../types';

export default defineComponent({
    name: 'FileHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<FileHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConfirmModal,
        ApolloTaskShell,
    },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();
        const { uploadPolicyFiles, fileProgress } = usePolicyFileUpload(props.task.pipeline.id);

        const currentStep = ref<number>(0);
        const loadingFileHarvester = ref(false);
        const task = ref(props.task);

        const { loading: loadingTask, isFinalized, save, finalize } = useApolloTask(task);

        const showAppendFileModal = ref<boolean>(false);
        const hasAdditionalFile = ref<boolean>(false);
        const sample = ref<any>(props.task.processedSample);
        const finalSample = ref<any>(props.task.processedSample);
        const files = ref<FilesData>({
            sampleFile: null,
            dataFile: null,
        });
        const showFinalizeModal = ref<boolean>(false);

        const finalLoading = computed(() => props.loading || loadingTask.value || loadingFileHarvester.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show: hasSampleRun.value && !task.value?.processedSample?.length,
                enabled: !finalLoading.value && !!canExecuteSampleRun.value,
            },
            {
                key: 'view-processed-sample',
                show: !!hasSampleRun.value && !!task.value?.processedSample?.length,
                enabled: true,
            },
            { key: 'append', show: !!task.value?.canAddFiles, enabled: !!canAppendFiles.value },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: !finalLoading.value && task.value?.status !== TaskStatus.Deprecated && canBeFinalized.value,
            },
        ]);

        const updateProcessedSample = (processedSample: Array<Record<string, null>>) => {
            if (!processedSample) return;
            setSample(processedSample);
            task.value.processedSample = processedSample;
            currentStep.value = currentStep.value + 1;
        };

        const runOnSample = async () => {
            await save();
            await uploadPolicyFiles([
                {
                    file: files.value.sampleFile as TFile,
                    sample: true,
                    policy: {
                        folder: 'upload',
                        subfolder: `sample/${new Date().valueOf().toString()}`,
                    },
                },
            ]);
            executeSampleRun();
        };

        const hasSampleRun = computed(
            () => currentStep.value === 0 && props.task.configuration.fileType === FileType.Parquet,
        );
        const canExecuteSampleRun = computed(() => !!files.value.sampleFile && !!files.value.dataFile);
        const canAppendFiles = computed(() => !!files.value.dataFile);

        const { executeSampleRun, loadingSampleRun, onMessage } = useSampleRun(task, root, updateProcessedSample);

        const isJSONorXML = computed(() =>
            [FileType.JSON, FileType.XML].includes(props.task.configuration.fileType as FileType),
        );

        const canBeFinalized = computed(
            () =>
                currentStep.value === 1 &&
                ((!props.task.pipeline.isOnPremise && !!files.value.dataFile) ||
                    (props.task.pipeline.isOnPremise && !!props.task.configuration.inputFilename)) &&
                (!!files.value.sampleFile || !!sample.value) &&
                (!isJSONorXML.value || !!props.task.configuration.response.selectedItems.length),
        );

        const setFiles = (changedFiles: FilesData) => {
            if (changedFiles.sampleFile !== undefined) {
                files.value.sampleFile = changedFiles.sampleFile;
                if (changedFiles.sampleFile === null) {
                    task.value.processedSample = [];
                    sample.value = null;
                    finalSample.value = null;
                }
            }
            if (changedFiles.dataFile !== undefined) {
                files.value.dataFile = changedFiles.dataFile;
                if (props.task.canAddFiles) hasAdditionalFile.value = !!changedFiles.dataFile;
            }
        };

        const setSample = (sampleData: any) => {
            sample.value = sampleData;
            finalSample.value = sampleData;
            task.value.processedSample = [];
        };

        const setParams = (params: any) => {
            task.value.configuration.params = params;
        };

        const setFinalSample = (updatedFinalSample: any) => {
            finalSample.value = updatedFinalSample;
        };

        const saveTask = async () => {
            try {
                if (finalSample.value) {
                    const arraySample = R.is(Array, finalSample.value) ? finalSample.value : [finalSample.value];
                    task.value.configuration.sample = arraySample;
                    if (task.value.configuration.params) {
                        task.value.configuration.params.fields = arraySample.length ? Object.keys(arraySample[0]) : [];
                    }
                }
                await save();
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
                throw e;
            }
        };

        const finalizeTask = async () => {
            if (!canBeFinalized.value) return;
            try {
                loadingFileHarvester.value = true;
                await saveTask();
                const folder = 'upload';
                const filesToUpload: any[] = [];
                if (props.task.configuration.fileType !== FileType.Parquet) {
                    filesToUpload.push({
                        file: files.value.sampleFile as TFile,
                        sample: true,
                        policy: {
                            folder,
                            subfolder: `sample/${new Date().valueOf().toString()}`,
                        },
                    });
                }
                if (!props.task.pipeline.isOnPremise) {
                    filesToUpload.push({
                        file: files.value.dataFile as TFile,
                        policy: {
                            folder,
                            subfolder: new Date().valueOf().toString(),
                        },
                    });
                }
                await uploadPolicyFiles(filesToUpload);
                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                loadingFileHarvester.value = false;
            }
        };

        const appendFiles = () => {
            showAppendFileModal.value = true;
        };

        const appendAdditionalFile = async () => {
            loadingFileHarvester.value = true;
            await uploadPolicyFiles([
                {
                    file: files.value.dataFile as TFile,
                    policy: {
                        folder: 'upload',
                        subfolder: new Date().valueOf().toString(),
                    },
                },
            ]);
            await exec(ApolloAPI.run(props.task?.pipeline.id as string));
            loadingFileHarvester.value = false;
            root.$router.push({ name: 'data-checkin-jobs' });
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<FileHarvesterConfiguration>) => {
                task.value = newTask;
            },
        );

        onMounted(() => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.task.pipeline?.id);
        });

        return {
            files,
            sample,
            finalSample,
            setFiles,
            setSample,
            setParams,
            setFinalSample,
            showFinalizeModal,
            finalLoading,
            canBeFinalized,
            runOnSample,
            fileProgress,
            saveTask,
            finalizeTask,
            hasSampleRun,
            canExecuteSampleRun,
            canAppendFiles,
            appendFiles,
            showAppendFileModal,
            appendAdditionalFile,
            currentStep,
            wizardActions,
            loadingSampleRun,
            loadingFileHarvester,
        };
    },
});
