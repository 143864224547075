






































































































































































































import {
    ConfirmModal,
    ESearch,
    FacetsFilters,
    Pagination,
    Scrollbar,
    SearchTerms,
    SvgImage,
    TailwindTableTabs,
    TwSelect,
} from '@/app/components';
import { useFacetsFilters, useSearch } from '@/app/composable';
import { useRoute } from '@/app/composable/router';
import store from '@/app/store';
import { useAlerts } from '@/modules/alert/composable';
import { AlertSourceType } from '@/modules/alert/constants/alert.constants';
import { RefreshPageKeys } from '@/modules/notification/constants';
import { SearchAPI } from '@/modules/search/api';
import { FireIcon, PlusIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ApolloAPI } from '../api';
import DataCheckinPipelineOverview from '../components/DataCheckinPipelineOverview.vue';
import FakeDataCheckinPipelineOverview from '../components/FakeDataCheckinPipelineOverview.vue';
import { useDataCheckinFilters } from '../composable';
import { DataCheckinSortingOption, TaskExecutionStatus, WorkflowExecutionStatus } from '../constants';
import { IndexedApolloPipeline } from '../types';

export default defineComponent({
    name: 'DataCheckinPipelines',
    metaInfo: {
        title: 'Data Check-in Pipelines',
    },
    components: {
        ConfirmModal,
        OrbitSpinner,
        DataCheckinPipelineOverview,
        SvgImage,
        Pagination,
        FireIcon,
        PlusIcon,
        TailwindTableTabs,
        FilterIcon,
        FacetsFilters,
        TwSelect,
        SearchTerms,
        FakeDataCheckinPipelineOverview,
        Scrollbar,
        RefreshIcon,
        ESearch,
    },
    setup(props, { root }) {
        const refresh = computed(() => {
            return store.state.notificationEngine.refresh[RefreshPageKeys.DataCheckinJob];
        });

        const searchableFields = [
            {
                label: 'title',
                key: 'name',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
        ];

        const { checkAlert, fetchAlerts } = useAlerts(root, [AlertSourceType.DataCheckin]);
        fetchAlerts();

        const queryParams = computed(() => JSON.stringify(root.$route.query));
        const route = useRoute();
        const highlightPipelineId = ref<string | null>(route.params.id); // Highlights a pipeline (when redirected from creating/updating, etc)

        // Delete confirmation modal and pointer
        const showDeleteModal = ref<boolean>(false);
        const toBeDeleted = ref<string | null>(null);
        const notificationReferenceType = ref(root.$route.params.type ?? null) as any;
        const pageSize = 10;
        const openFilters = ref<boolean>(true);
        const pipelines = ref<IndexedApolloPipeline[]>([]);
        const rejectedItems = ref<number>(0);
        const totalResults = ref<number>(0);
        const user = ref(store.state.auth.user);

        const setSearchQuery = () => {
            searchQuery.value.filters =
                tab.value === 'mine'
                    ? Object.fromEntries(
                          Object.entries(reducedDataCheckinFilters).filter(([key]) => !key.includes('user')),
                      )
                    : reducedDataCheckinFilters;
        };

        const error = ref<any>(null);

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.pipelineType = 'data-checkin';

            searchQuery.value.settings = {
                organisationId: tab.value === 'shared' && user.value.organisationId ? user.value.organisationId : null,
                userId: user.value.id,
            };

            const api = tab.value === 'mine' ? SearchAPI.myJobsSearch : SearchAPI.sharedJobsSearch;

            setQueryParams();

            exec(api(searchQuery.value))
                .then((res: any) => {
                    pipelines.value = res.data?.results;
                    rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                    totalResults.value = res.data?.total;
                    error.value = null;
                })
                .catch((e: any) => (error.value = e));
        };

        const {
            exec,
            loading,
            get,
            textNumberFilters,
            page,
            sortBy,
            tab,
            query,
            searchQuery,
            setTab,
            updateCurrentPage,
            filterChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(root, 'data-checkin-jobs', pageSize, DataCheckinSortingOption, 'mine', setSearchQuery, search);
        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
            if (refresh.value) store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.DataCheckinJob);
        });

        const tabs = computed(() => [
            { key: 'mine', label: 'My Pipelines' },
            { key: 'shared', label: 'Pipelines Shared with Me' },
        ]);

        const { dataCheckinFilters, reducedDataCheckinFilters } = useDataCheckinFilters(get);
        const { calculateSearchedFilters, defaultValue } = useFacetsFilters();

        const filtersInfo = computed(() => {
            switch (tab.value) {
                case 'mine':
                    return Object.fromEntries(
                        Object.entries(dataCheckinFilters.value).filter(([key]) => !key.includes('user')),
                    );
                default:
                    return dataCheckinFilters.value;
            }
        });

        watch(showDeleteModal, (value: boolean) => {
            if (!value) toBeDeleted.value = null;
        });

        const edit = (id: string) => {
            root.$router.push({ name: 'data-checkin-jobs:edit', params: { id, queryParams: queryParams.value } });
        };

        const openHistory = (id: string) => {
            root.$router.push({ name: 'data-checkin-jobs:history', params: { id, queryParams: queryParams.value } });
        };

        const confirmDelete = (id: string) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const destroy = async () => {
            if (toBeDeleted.value) {
                const idx = R.findIndex(R.propEq('id', toBeDeleted.value), pipelines.value as any[]);
                if (~idx) {
                    showDeleteModal.value = false;
                    try {
                        const referenceId = toBeDeleted.value;
                        await exec(ApolloAPI.delete(toBeDeleted.value));

                        store.commit.notificationEngine.SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE({
                            referenceId,
                            referenceType: notificationReferenceType.value
                                ? notificationReferenceType.value
                                : 'data-checkin',
                        });
                        (root as any).$toastr.s('Data Check-in Pipeline deleted successfuly', 'Success');
                        pipelines.value.splice(idx, 1);
                    } catch (e: any) {
                        if (e.response?.data?.message === 'Locked')
                            (root as any).$toastr.e('The Data Check-in Pipeline is locked', 'Error');
                        else (root as any).$toastr.e('Deleting Data Check-in Pipeline failed', 'Error');
                    }
                }
            }
        };

        const retryExecution = async (pipelineId: string) => {
            await exec(ApolloAPI.run(pipelineId))
                .then(() => {
                    const foundPipeline = pipelines.value.find(
                        (pipeline: IndexedApolloPipeline) => pipeline.id === pipelineId,
                    );
                    if (foundPipeline) {
                        for (let i = 0; i < foundPipeline.tasks.length; i++)
                            foundPipeline.tasks[i].status = TaskExecutionStatus.Queued;
                        foundPipeline.executionStatus = WorkflowExecutionStatus.Queued;
                    }
                    (root as any).$toastr.s('Data check-in pipeline resubmitted for execution');
                })
                .catch(() => {
                    (root as any).$toastr.e('Data check-in pipeline resubmission failed', 'Error');
                });
        };

        const clonePipeline = async (pipelineId: string) => {
            (root as any).$router.push({
                name: 'data-checkin-jobs:clone',
                params: { id: pipelineId, queryParams: queryParams.value },
            });
        };

        const currentSearchedFilters = computed(() =>
            calculateSearchedFilters(searchQuery.value.filters, filtersInfo.value),
        );

        const refreshPage = () => {
            search();
            store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.DataCheckinJob);
        };
        textNumberFilters.value = { pipeline: defaultValue(get, 'pipeline', false) };
        setSearchQuery();
        search();

        return {
            confirmDelete,
            destroy,
            edit,
            error,
            pipelines,
            loading,
            pageSize,
            showDeleteModal,
            page,
            openHistory,
            retryExecution,
            clonePipeline,
            tabs,
            setTab,
            tab,
            filterChanged,
            clearFilters,
            openFilters,
            query,
            searchQuery,
            sortingFields,
            sortByChanged,
            sortBy,
            updateCurrentPage,
            currentSearchedFilters,
            totalResults,
            rejectedItems,
            filtersInfo,
            queryParams,
            textNumberFilters,
            refresh,
            refreshPage,
            highlightPipelineId,
            checkAlert,
            searchableFields,
        };
    },
});
