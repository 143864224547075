var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormBlock',{attrs:{"title":"Connection Details","description":"The connection details which will be used to collect your streaming data"}},[(!_vm.isFinalized)?_c('AlertBanner',{staticClass:"text-sm",attrs:{"type":"warn"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Make sure that the combination of the provided topic and group id is not used by another running data check-in pipeline, as it may cause inconsistencies when trying to read messages from the topic. ")]},proxy:true}],null,false,2759115261)}):_vm._e(),_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Connection URL")])]),_c('ValidationProvider',{staticClass:"w-1/2 rounded-md",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.url),expression:"configuration.connectionDetails.url"}],staticClass:"block w-full form-input sm:text-sm sm:leading-5 text-neutral-800",class:{
                    'border-red-700': errors.length > 0,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"id":"url","type":"text","placeholder":"Enter the Connection URL","disabled":_vm.isFinalized},domProps:{"value":(_vm.configuration.connectionDetails.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.configuration.connectionDetails, "url", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Topic")])]),_c('ValidationProvider',{staticClass:"w-1/2 rounded-md",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.topic),expression:"configuration.connectionDetails.topic"}],staticClass:"block w-full form-input sm:text-sm sm:leading-5 text-neutral-800",class:{
                    'border-red-700': errors.length > 0,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"id":"topic","type":"text","placeholder":"Enter the topic","disabled":_vm.isFinalized},domProps:{"value":(_vm.configuration.connectionDetails.topic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.configuration.connectionDetails, "topic", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Group Id")])]),_c('ValidationProvider',{staticClass:"w-1/2 rounded-md",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.groupId),expression:"configuration.connectionDetails.groupId"}],staticClass:"block w-full form-input sm:text-sm sm:leading-5 text-neutral-800",class:{
                    'border-red-700': errors.length > 0,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"id":"groupId","type":"text","placeholder":"Enter the group Id","disabled":_vm.isFinalized},domProps:{"value":(_vm.configuration.connectionDetails.groupId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.configuration.connectionDetails, "groupId", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Security protocol")])]),_c('ValidationProvider',{staticClass:"w-1/2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.securityProtocol),expression:"configuration.connectionDetails.securityProtocol"}],staticClass:"block w-full text-sm form-select text-neutral-800",class:{
                    'text-neutral-500': !_vm.configuration.connectionDetails.securityProtocol,
                    'border-red-700': errors.length,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"name":"securityProtocol","disabled":_vm.isFinalized},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.configuration.connectionDetails, "securityProtocol", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeSecurityProtocol]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select Security protocol...")]),_vm._l((_vm.securityProtocols),function(securityProtocol,idx){return _c('option',{key:idx,domProps:{"value":securityProtocol}},[_vm._v(" "+_vm._s(securityProtocol)+" ")])})],2)]}}])})],1),(_vm.isSaslSecurityProtocol)?_c('div',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("SASL mechanism")])]),_c('ValidationProvider',{staticClass:"w-1/2",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.saslMechanism),expression:"configuration.connectionDetails.saslMechanism"}],staticClass:"block w-full text-sm form-select text-neutral-800",class:{
                    'text-neutral-500': !_vm.configuration.connectionDetails.saslMechanism,
                    'border-red-700': errors.length,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"name":"saslMechanism","disabled":_vm.isFinalized},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.configuration.connectionDetails, "saslMechanism", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeSASLMechanism]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select SASL mechanism...")]),_vm._l((_vm.saslMechanisms),function(saslMechanism,idx){return _c('option',{key:idx,domProps:{"value":saslMechanism}},[_vm._v(" "+_vm._s(saslMechanism)+" ")])})],2)]}}],null,false,446246734)})],1):_vm._e(),(!_vm.isNoneSaslMechanish)?_c('span',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Username")])]),_c('ValidationProvider',{staticClass:"w-1/2 rounded-md",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.username),expression:"configuration.connectionDetails.username"}],staticClass:"block w-full form-input sm:text-sm sm:leading-5 text-neutral-800",class:{
                    'border-red-700': errors.length > 0,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"id":"username","type":"text","placeholder":"Enter the username","disabled":_vm.isFinalized},domProps:{"value":(_vm.configuration.connectionDetails.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.configuration.connectionDetails, "username", $event.target.value)}}})]}}],null,false,2082576804)})],1):_vm._e(),(!_vm.isNoneSaslMechanish)?_c('span',{staticClass:"flex items-center flex-auto"},[_c('div',{staticClass:"flex-initial w-32 mr-2 text-sm text-neutral-700"},[_c('span',[_vm._v("Password")])]),(!_vm.isFinalized)?_c('ValidationProvider',{staticClass:"w-1/2 rounded-md",attrs:{"rules":{ required: !_vm.isFinalized }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.configuration.connectionDetails.password),expression:"configuration.connectionDetails.password"}],staticClass:"block w-full form-input sm:text-sm sm:leading-5 text-neutral-800",class:{
                    'border-red-700': errors.length > 0,
                    'bg-neutral-200 text-neutral-700': _vm.isFinalized,
                },attrs:{"id":"password","type":"password","placeholder":"Enter the password","disabled":_vm.isFinalized},domProps:{"value":(_vm.configuration.connectionDetails.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.configuration.connectionDetails, "password", $event.target.value)}}})]}}],null,false,2700387750)}):[_c('div',{staticClass:"flex-auto text-sm italic text-neutral-600"},[_vm._v(" The password you have provided cannot be displayed due to security reasons ")])]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }