










import { useRoute } from '@/app/composable/router';
import { WorkflowType } from '@/app/constants/workflow-type.constants';
import { ExecutionHistory } from '@/modules/workflow-designer/components/execution-history';
import { computed, defineComponent } from '@vue/composition-api';
import { useApolloPipeline } from '../composable';

export default defineComponent({
    name: 'DataCheckinPipelineExecutionHistory',
    props: {
        id: {
            type: String,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: { ExecutionHistory },
    setup: (props: any, { root }: { root: any }) => {
        const route = useRoute();

        const onFetchPipelineError = (e: any) => {
            if (e.response?.status === 403) {
                (root as any).$toastr.e('You do not have access to view this page.', 'Access Denied');
                root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
            } else {
                root.$toastr.e(`Data Checkin-in Pipeline could not be fetched`, 'Error');
            }
        };

        const { pipeline, isLongRunning } = useApolloPipeline(props.id, onFetchPipelineError);

        const routerName = computed(() => route.name);

        return { pipeline, WorkflowType, routerName, isLongRunning };
    },
});
