






































































import { TwButton } from '@/app/components';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { HarvesterBlockId, RetrievalType, SQLRetrievalMethod } from '../../../constants';
import { SAPHanaHarvesterConfiguration } from '../../../types';
import { DataRetrievalDetails, RetrievalSettings, Schedules, SQLConnectionDetails } from '../common';
import { InformationCircleIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'SetupSAPHanaHarvester',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<SAPHanaHarvesterConfiguration>,
            required: true,
        },
        pipelineFinalized: {
            type: Boolean,
            default: false,
        },
        schedules: {
            type: Array as PropType<ScheduleType[]>,
            default: () => [],
        },
        scheduleDetailsDefined: {
            type: Boolean,
            default: false,
        },
        invalidQuery: {
            type: Boolean,
            default: false,
        },
        containsModificationTerms: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isRunning: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        hasCompleted: {
            type: Boolean,
            default: false,
        },
        inDraftStatus: {
            type: Boolean,
            default: false,
        },
        pipelineId: {
            type: String,
            default: '',
        },
        hasAnonymisation: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        RetrievalSettings,
        DataRetrievalDetails,
        Schedules,
        SQLConnectionDetails,
        ValidationObserver,
        TwButton,
        ChevronRightIcon,
        InformationCircleIcon,
    },
    setup(props, { emit }) {
        const sapHanaValidationRef = ref<any>();

        const sapHanaConfiguration: Ref<SAPHanaHarvesterConfiguration> = computed({
            get: () => props.configuration,
            set: (newConfiguration: SAPHanaHarvesterConfiguration) => {
                emit('change', newConfiguration);
            },
        });

        const validateAndProceed = async () => {
            if (!sapHanaValidationRef.value) return;

            const valid = await sapHanaValidationRef.value.validate();
            if (!valid) return;

            emit('check-schedules-and-proceed');
        };

        const retrieveOnce = computed(() => sapHanaConfiguration.value.retrieval.type === RetrievalType.Once);

        const disableNext = computed(
            () =>
                !props.scheduleDetailsDefined ||
                (sapHanaConfiguration.value.method === SQLRetrievalMethod.Query &&
                    (props.containsModificationTerms || props.invalidQuery)),
        );

        const readOnlyMessage = computed(() => {
            if (!props.isOwner) return 'Only the owner of the pipeline is allowed to update its schedules';
            if (retrieveOnce.value) {
                if (props.hasCompleted) return 'You cannot update a schedule that has already been executed';
                if (props.isRunning) return 'You cannot update a schedule that is currently being executed';
            }
            return null;
        });

        const queryOrTableChanged = () => {
            emit('clear-processed-sample');
            sapHanaConfiguration.value.identifierColumn = null;
        };

        const clearProcessedSample = () => {
            emit('clear-processed-sample');
        };

        return {
            sapHanaConfiguration,
            HarvesterBlockId,
            disableNext,
            validateAndProceed,
            readOnlyMessage,
            queryOrTableChanged,
            retrieveOnce,
            sapHanaValidationRef,
            clearProcessedSample,
        };
    },
});
